import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        
        <h1>
           Elisa
        </h1>
<a href="https://wc-duck.fi/">
<img className="ankka" src="https://www.thespruce.com/thmb/t13CIs9CH0HfuggdQ-DU9zk_QHo=/3780x2126/smart/filters:no_upscale()/do-ducks-have-teeth-4153828-hero-9614a7e9f4a049b48e8a35a9296c562c.jpg"/>
        </a>
        <button className="ankkabutton" onClick={()=>alert("ah ah")}>Paina minua</button>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          is beautiful
        
        </a> <p>Tänään oli kylmä kunnes on lämmin</p>
      </header>
    </div>
  );
}

export default App;
